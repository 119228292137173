import React from 'react'
import { func, bool } from 'prop-types'

import ButtonWithIcon from '../ButtonWithIcon'

const Actions = ({
    onReject,
    onApprove,
    disabledApprove,
    disabledReject,
    horizontal
}) => {
    return (
        <>
            <ButtonWithIcon
                className={horizontal ? 'mal-4' : 'mab-2'}
                disabled={disabledApprove}
                iconAttrs={{ className: 'co-teal-2' }}
                iconName="check"
                onClick={onApprove}
            >
                Approve
            </ButtonWithIcon>
            <ButtonWithIcon
                className={horizontal ? 'mal-4' : 'mab-2'}
                disabled={disabledReject}
                iconAttrs={{ className: 'co-orange-2' }}
                iconName="close"
                onClick={onReject}
            >
                Reject
            </ButtonWithIcon>
        </>
    )
}

Actions.propTypes = {
    onReject: func.isRequired,
    onApprove: func.isRequired,
    disabledApprove: bool,
    disabledReject: bool,
    horizontal: bool
}

Actions.defaultProps = {
    disabledApprove: false,
    disabledReject: false,
    horizontal: false
}

export default Actions