import React from 'react'
import cx from 'classnames'
import { number, shape, string } from 'prop-types'

import styles from './_.module.scss'

const LimitsDisclaimer = ({
  length,
  min,
  minError,
  max,
  maxError,
  recommended
}) => (
  <div className={cx('baco-gray-1', 'pax-3', 'bowi-1', 'bost-s', styles.wrapper)}>
    <div
      className={cx('fosi-1', 'pay-1', 'co-black', 'di-f', styles.container)}
    >
      <div>
        {length} of {max} max.
      </div>
      <span className="co-star-4 pal-1">
        Recommended {recommended.min}-{recommended.max} words.
      </span>
      {length < min && <span className="co-star-4 pal-1">{minError}</span>}
      {length > max && <span className="co-star-4 pal-1">{maxError}</span>}
    </div>
  </div>
)

LimitsDisclaimer.propTypes = {
  length: number.isRequired,
  recommended: shape({
    max: number.isRequired,
    min: number.isRequired
  }).isRequired,
  max: number,
  maxError: string,
  min: number,
  minError: string
}
LimitsDisclaimer.defaultProps = {
  min: -1,
  minError: null,
  max: -1,
  maxError: null
}

export default LimitsDisclaimer
