import React from 'react'
import { any, array, func, bool } from 'prop-types'

// components
import Table from '@components/Table'
import TableTr from './components/TableTr'

// modules
import { columns } from './modules/constants'

const ModeratorTable = ({
  photos,
  onPhotoUpdated,
  isPhotoUpdating,
  setIsPhotoUpdating,
  setBigImage,
  photosBatch,
  updatePhotosBatch,
  multipleSelection
}) => {
  if(!multipleSelection){
    columns.splice(0,1)
  }

  return (
    <>
      <Table className="mat-3">
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key} className="pa-3">
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {photos.map((photo) => <TableTr
                key={photo.publicId}
                photo={photo}
                multipleSelection={multipleSelection}
                onPhotoUpdated={onPhotoUpdated}
                setIsPhotoUpdating={setIsPhotoUpdating}
                isPhotoUpdating={isPhotoUpdating}
                setBigImage={setBigImage}
                photosBatch={photosBatch}
                updatePhotosBatch={updatePhotosBatch}
              />)
            }
          </tbody>
      </Table>
    </>
  )
}

ModeratorTable.propTypes = {
  photos: array,
  isPhotoUpdating: bool,
  multipleSelection: bool,
  setIsPhotoUpdating: func,
  setBigImage: func,
  photosBatch: any,
  updatePhotosBatch: func,
  onPhotoUpdated: func
}

ModeratorTable.defaultProps = {
  photos: [],
  onPhotoUpdated: () => {},
  setIsPhotoUpdating: () => {},
  setBigImage: () => {},
  photosBatch: new Map(),
  updatePhotosBatch: () => {},
  isPhotoUpdating: false,
  multipleSelection: false
}

export default ModeratorTable
