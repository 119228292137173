import React, { Component } from 'react'
import { node, string } from 'prop-types'
import cx from 'classnames'

// modules
import withQAPrefix from '@hoc/withQAPrefix'

// components
import TableContainer from '../TableContainer'

// styles
import styles from './_.module.scss'

class Table extends Component {
  render() {
    const { qaPrefix, className, children } = this.props
    return (
      <TableContainer>
        <table
          className={cx('teal-l', `${qaPrefix}table`, className, styles.table)}
          cellPadding={0}
          cellSpacing={0}
        >
          {children}
        </table>
      </TableContainer>
    )
  }
}

Table.propTypes = {
  children: node,
  className: string,
  qaPrefix: string
}

Table.defaultProps = {
  children: null,
  className: '',
  qaPrefix: ''
}
export default withQAPrefix(Table)
