import React from 'react'
import { func, array, bool } from 'prop-types'

// components
import EditControls from '@components/EditControls'
import { THEMES } from '@components/constants'

const Footer = ({ updating, exit, setUpdating, onConfirm, photos }) => {
  return (
    !updating && (
      <EditControls
        disabled={updating}
        onCancel={exit}
        okText="Yes"
        cancelText="No"
        okPalette={THEMES.orange}
        onSave={() => {
          setUpdating(true)
          onConfirm({
            photos,
            closeModal: () => {
              setUpdating(false)
              exit()
            }
          })
        }}
      />
    )
  )
}

Footer.propTypes = {
  updating: bool,
  exit: func,
  photos: array,
  setUpdating: func,
  onConfirm: func
}
Footer.defaultProps = {
  updating: false,
  exit: () => null,
  photos: [],
  setUpdating: () => null,
  onConfirm: () => null
}

export default Footer
