export const optionGroups = [
  {
    label: 'Status',
    key: 'status',
    options: [
      { label: 'Pending', value: '1' },
      { label: 'Approved', value: '2' },
      { label: 'Rejected', value: '4' }
    ]
  },
  {
    label: 'Type',
    key: 'type',
    options: [
      { label: 'Image', value: '1' },
      { label: 'Logo', value: '2' }
    ]
  }
]
