export const columns = [
  { label: '', key: 'checkbox' },
  { label: 'Organization', key: 'organization' },
  { label: 'Community', key: 'community' },
  { label: 'Uploaded By', key: 'uploaded_by' },
  { label: 'Uploaded Date', key: 'uploaded_date' },
  { label: 'Status', key: 'status' },
  { label: 'Preview', key: 'preview' },
  { label: 'Actions', key: 'actions' }
]

export const randomizedData = {
  organization: ['Charter Senior Living'],
  name: ['Charter Senior Living of Davison'],
  state: ['WA', 'CA', 'WY', 'OR', 'CN'],
  care_type: ['AZ', 'A', 'Z', 'AZ', 'A', 'Z'],
  modified_date: ['3/11/2019', '3/11/2019'],
  modified_by: ['marketing@charterdavison.com', 'marketing@charterdavison.com'],
  status: ['Pending', 'Approved', 'Rejected'],
  preview: [],
  action: []
}
