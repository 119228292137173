import React, { memo, useState } from 'react'
import { any, object, func, bool } from 'prop-types'

// components
import ImagePreviewer from '@components/ImagePreviewer'
import Checkbox from '@components/Checkbox'
import Actions from '../Actions'

// modules
import Cloudinary from '@modules/cloudinary'
import Formatter from '@modules/formatter'
import { IMAGE_STATUSES_MAP, IMAGE_STATUSES } from '@modules/constants'
import { batchUpdatePhotos } from '../../../../modules/utils'

const TableTr = ({
  photo,
  onPhotoUpdated,
  isPhotoUpdating,
  setIsPhotoUpdating,
  setBigImage,
  photosBatch,
  updatePhotosBatch,
  multipleSelection
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { metadata } = photo
  const photoStatus = parseInt(metadata.status, 10)
  const thumbUrl = Cloudinary.makeThumbnailFromSecureUrl(photo.secureUrl, 150)

  return (
    <>
      {photo && (
        <tr>
          {multipleSelection && (
            <td className="pay-3 pax-1">
              <Checkbox.Controlled
                name={photo.publicId}
                onChange={() => {
                  updatePhotosBatch(photo)
                }}
                checked={photosBatch.has(photo.publicId)}
              />
            </td>
          )}
          <td className="pa-3">
            {metadata && metadata.organizationName
              ? metadata.organizationName
              : 'Unknown'}
          </td>
          <td className="pa-3">
            {metadata && metadata.communityName
              ? metadata.communityName
              : 'Unknown'}
          </td>
          <td className="pa-3">
            {metadata && metadata.uploadedByName
              ? metadata.uploadedByName
              : 'Unknown'}
          </td>
          <td className="pa-3">
            {photo.uploadedAt
              ? `${Formatter.date(photo.uploadedAt)} ${Formatter.time(
                  photo.uploadedAt
                )}`
              : 'Unknown'}
          </td>
          <td className="pa-3">
            {metadata &&
              IMAGE_STATUSES_MAP.get(metadata.status ? +metadata.status : 1)}
          </td>
          <td className="pa-3">
            <span
              className="cu-p"
              onClick={() => {
                setBigImage(photo)
              }}
            >
              <ImagePreviewer imageUrl={thumbUrl} isSmall={true} />
            </span>
          </td>
          <td className="pa-3">
            {metadata && (
              <>
                {isLoading ? (
                  <span>Updating...</span>
                ) : (
                  <Actions
                    onReject={() => {
                      setIsLoading(true)
                      setIsPhotoUpdating(true)
                      batchUpdatePhotos(
                        [photo],
                        IMAGE_STATUSES.rejected,
                        (err, res) => {
                          setIsLoading(false)
                          onPhotoUpdated(err, res)
                        }
                      )
                    }}
                    onApprove={() => {
                      setIsLoading(true)
                      setIsPhotoUpdating(true)
                      batchUpdatePhotos(
                        [photo],
                        IMAGE_STATUSES.approved,
                        (err, res) => {
                          setIsLoading(false)
                          onPhotoUpdated(err, res)
                        }
                      )
                    }}
                    disabledApprove={
                      photoStatus === IMAGE_STATUSES.approved || isPhotoUpdating
                    }
                    disabledReject={
                      photoStatus === IMAGE_STATUSES.rejected || isPhotoUpdating
                    }
                  />
                )}
              </>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

TableTr.propTypes = {
  photo: object.isRequired,
  isPhotoUpdating: bool,
  multipleSelection: bool,
  setIsPhotoUpdating: func,
  setBigImage: func,
  photosBatch: any,
  updatePhotosBatch: func,
  onPhotoUpdated: func
}

TableTr.defaultProps = {
  photo: {
    metadata: {}
  },
  isPhotoUpdating: false,
  multipleSelection: false,
  setIsPhotoUpdating: () => {},
  setBigImage: () => {},
  photosBatch: new Map(),
  updatePhotosBatch: () => {},
  onPhotoUpdated: () => {}
}

export default memo(TableTr)
