import React from 'react'
import { func, node, object, string, bool } from 'prop-types'
import withThemeClassNames from '../../hoc/withThemeClassNames'
import withPalette from '../../hoc/withPalette'
import { THEMES } from '../constants'
import cx from 'classnames'

// styles
import styles from './_.module.scss'

const Button = ({
  children,
  onClick,
  theme,
  themeClassNames,
  className,
  palette,
  disabled,
  ...rest
}) => (
  <button
    className={cx(
      styles.button,
      'pax-2',
      'pay-1',
      'no',
      'tetr-c',
      'bora',
      'teal-c',
      'bosh-1',
      'di-f',
      'alit-c',
      'alco-c',
      themeClassNames.backgroundColor,
      styles[palette],
      className
    )}
    onClick={onClick}
    disabled={disabled || false}
    aria-label="action button"
    {...rest}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  palette: string,
  theme: string,
  themeClassNames: object,
  onClick: func
}

Button.defaultProps = {
  onClick: () => null,
  palette: THEMES.primary,
  children: null,
  className: '',
  disabled: false,
  themeClassNames: {},
  theme: ''
}

export default withPalette(withThemeClassNames(Button))
