import React from 'react'
import { func, string, bool } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'

// styles
import styles from './_.module.scss'

const Controls = ({ onSave, save, onCancel, cancel, className }) => (
  <section className={cx('di-f', 'wi-100', styles.actions, className)}>
    <div/>
    <div className="di-f juco-fe fosi-1">
      {
        cancel ? (
          <Button className="pay-2 pax-5 mar-2" palette="white" onClick={onCancel}>
            Cancel
          </Button>
        ) : null
      }

      {
        save ? (
          <Button className="pay-2 pax-5" onClick={onSave} type="button">
            Save
          </Button>
        ) : null
      }
    </div>
  </section>
)

Controls.propTypes = {
  cancel: bool,
  className: string,
  save: bool,
  onCancel: func,
  onSave: func
}

Controls.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  className: '',
  save: true,
  cancel: true
}

export default Controls
