import React from 'react'
import { any, string, object } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'
import Icon from '@components/Icon'

// styles
import styles from './_.module.scss'

const ButtonWithIcon = ({ children, iconName, iconAttrs, ...rest }) => (
    <Button className={cx("mab-1", { [styles.disabled]: rest.disabled })} palette="white" {...rest}>
        {children} <Icon name={iconName} {...iconAttrs} />
    </Button>
)

ButtonWithIcon.propTypes = {
    iconName: string.isRequired,
    children: any,
    iconAttrs: object
}

ButtonWithIcon.defaultProps = {
    children: null,
    iconAttrs: {}
}

export default ButtonWithIcon