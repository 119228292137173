import React, { Component } from 'react'
import { bool, node, string } from 'prop-types'

// Modules
import withQAPrefix from '@hoc/withQAPrefix'

class RowsGroup extends Component {
  renderElement(rest) {
    const Element = ({ isHeading, qaPrefix, ...rest }) =>
      isHeading ? (
        <thead className={`${qaPrefix}__table--header`} {...rest} />
      ) : (
        <tbody className={`${qaPrefix}__table--body`} {...rest} />
      )
    return <Element {...rest} />
  }

  render() {
    return this.renderElement({ ...this.props })
  }
}

RowsGroup.propTypes = {
  children: node,
  isHeading: bool,
  qaPrefix: string
}

RowsGroup.defaultProps = {
  children: null,
  isHeading: false,
  qaPrefix: ''
}

export default withQAPrefix(RowsGroup)
