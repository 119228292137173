import React, { Component } from 'react'
import { bool, func, node } from 'prop-types'

class Cell extends Component {
  renderElement(rest) {
    const Element = ({ isHeading, onClick, ...rest }) =>
      isHeading ? (
        <th role="columnheader" onClick={onClick} {...rest} />
      ) : (
        <td role="gridcell" {...rest} />
      )
    return <Element {...rest} />
  }

  render() {
    return this.renderElement({ ...this.props })
  }
}

Cell.propTypes = {
  children: node,
  isHeading: bool,
  onClick: func
}

Cell.defaultProps = {
  isHeading: false,
  onClick: null,
  children: null
}

export default Cell
