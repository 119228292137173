import React, { Component } from 'react'
import cx from 'classnames'
import { string, func, oneOfType, number, bool } from 'prop-types'

// hoc
import withPalette from '@hoc/withPalette'

//  modules
import DOM from '@modules/dom'

// components
import Icon from '@components/Icon'
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

const componentPropTypes = [
  'className',
  'onChange',
  'getValue',
  'setValue',
  'isPristine',
  'getErrorMessage',
  'getErrorMessages',
  'errorMessages',
  'errorMessage',
  'title',
  'setValidations',
  'showRequired',
  'showError',
  'showErrors',
  'palette',
  'hasValue',
  'isFormDisabled',
  'isFormSubmitted',
  'isRequired',
  'isValid',
  'isValidValue',
  'resetValue',
  'validationError',
  'validationErrors'
]
const STATE_STATUS = 'checkboxStatus'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    const { value, defaultValue, checked } = this.props
    this.state = {
      [STATE_STATUS]: value || defaultValue || checked || false
    }
  }
  handleChange = event => {
    const value = !!event.currentTarget.checked
    const { onChange, name } = this.props
    this.setState({
      [STATE_STATUS]: value
    })
    onChange(name, value)
  }

  initElementRef = element => {
    this.element = element
  }

  render() {
    const {
      id,
      name,
      palette,
      className,
      errorMessage,
      title
    } = this.props
    const uniqueId = id || DOM.getUniqueId(name)
    const isChecked = this.state[STATE_STATUS]
    const inputProps = Object.assign({}, this.props)
    componentPropTypes.forEach(key => {
      delete inputProps[key]
    })
    const paletteSelected = `checkbox${palette
      .charAt(0)
      .toUpperCase()}${palette.slice(1)}`
    return (
      <div
        className={cx('di-f', 'fldi-c', {
          [styles.withError]: !!errorMessage
        })}
      >
        <div className={cx('di-f', 'alit-c', className)}>
          <div className={cx('di-f', 'cu-p', 'po-r', 'mar-2')}>
            <input
              {...inputProps}
              id={uniqueId}
              className={cx(
                'di-f',
                styles.checkbox,
                styles[paletteSelected] || '',
                { [styles.checkboxDisabled]: inputProps.disabled }
              )}
              type="checkbox"
              checked={isChecked}
              onChange={this.handleChange}
              ref={this.initElementRef}
            />
            <Icon
              className="po-a"
              name="check"
              size={12}
              onClick={() => this.element.click()}
            />
          </div>
          <label className={cx('di-f', 'cu-p')} htmlFor={inputProps.id}>
            {title}
          </label>
        </div>
        <div
          className={cx(
            'fosi-1',
            'teal-l',
            'pax-3',
            'pay-1',
            styles.errorMessage
          )}
        >
          {errorMessage}
        </div>
      </div>
    )
  }
}

Checkbox.propTypes = {
  id: string,
  name: string,
  className: string,
  value: oneOfType([string, number]),
  title: string,
  palette: string,
  defaultValue: oneOfType([string, number, bool]),
  checked: bool,
  errorMessage: string,
  onChange: func
}

Checkbox.defaultProps = {
  id: null,
  name: null,
  className: null,
  value: undefined,
  title: null,
  palette: THEMES.primary,
  defaultValue: undefined,
  checked: false,
  errorMessage: null,
  onChange: () => null
}

export default withPalette(Checkbox)
