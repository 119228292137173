import React, { Component } from 'react'
import { THEMES, COLOR_LEVELS } from '../components/constants'

const DEFAULT_THEME = THEMES.primary
const DEFAULT_THEME_LEVEL = 2
const DEFAULT_THEME_BORDER_WIDTH = 0

export default function(WrappedComponent, config = {}) {
  return class extends Component {
    render() {
      const { props } = this

      const _config = {
        ...props,
        ...config
      }

      let {
        theme,
        themeTendency,
        themeLevel,
        themeBorderWidth,
        ...rest
      } = _config

      theme = THEMES[theme] || DEFAULT_THEME
      themeLevel = COLOR_LEVELS[themeLevel] || DEFAULT_THEME_LEVEL
      themeBorderWidth =
        COLOR_LEVELS[themeBorderWidth] || DEFAULT_THEME_BORDER_WIDTH

      const themeClassNames = {
        backgroundColor: `baco-${theme}-${themeLevel}`,
        borderColor: `boco-${theme}-${themeLevel}`,
        borderWidth: `bowi-${themeBorderWidth}`
      }

      return <WrappedComponent {...rest} themeClassNames={themeClassNames} />
    }
  }
}
