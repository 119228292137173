import Table from './components/Table'
import RowGroup from './components/RowGroup'
import Row from './components/Row'
import Cell from './components/Cell'
import Head from './components/Head'

Table.RowGroup = RowGroup
Table.Row = Row
Table.Cell = Cell
Table.Head = Head

export default Table
