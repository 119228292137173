import React from 'react'
import { Router } from '@reach/router'

// compositions
import IndexPage from '@compositions/Moderator/components/Index'

const Moderator = () => (
  <Router>
    <IndexPage path="/moderator" />
  </Router>
)


export default Moderator
