import React from 'react'
import { func, array, number } from 'prop-types'

// components
import Modal from '@components/Modal'

// modules
import { IMAGE_TYPES } from '@modules/constants'
import { getStatusAsString } from '../../../../modules/utils'

const ConfirmationModal = ({ currentType, photos, status, closeModal }) => (
  <Modal className="po-r" onToggleActive={() => closeModal()}>
    <div className="di-f fldi-c max-2">
      <h4 className="fowe-b mab-1">{`${photos.length} ${
        currentType === IMAGE_TYPES.photo ? 'image' : 'logo'
      }${photos.length > 1 ? 's were' : ' was'} not ${getStatusAsString(
        status
      )}.`}</h4>
    </div>
  </Modal>
)

ConfirmationModal.propTypes = {
  currentType: number,
  photos: array,
  status: number,
  closeModal: func
}
ConfirmationModal.defaultProps = {
  currentType: 1,
  photos: [],
  status: 0,
  closeModal: () => null
}

export default ConfirmationModal
