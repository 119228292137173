import React, { Component } from 'react'

// modules
import { QA_PREFIXES } from '@components/constants'

export default function(WrappedComponent) {
  return class extends Component {
    render() {
      const { props } = this
      const qaPrefix = QA_PREFIXES[props.prefix] || QA_PREFIXES.default // eslint-disable-line react/prop-types
      return <WrappedComponent {...props} qaPrefix={qaPrefix} />
    }
  }
}
