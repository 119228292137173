import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { string, object } from 'prop-types'

// modules
import Url from '@modules/url'
import { Collection } from '@modules/rest-api'
import {
  REST_API_ENDPOINTS,
  MODERATOR_ROLE,
  USER_ROLES,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  IMPERSONATED_USER_ROLES
} from '@modules/constants'
import Auth from '@modules/auth'
import { batchUpdatePhotos, getStatusAsString } from './modules/utils'

// compositions
import { ToastNotify } from '@compositions/Toast'

// components
import Layout from '@compositions/Layout'
import PrivateRoute from '@components/PrivateRoute'
import ImageEditor from '@components/ImageEditor'
import Header from './components/Header'
import Table from './components/Table'
import ConfirmationModal from './components/Table/components/ConfirmationModal'
import FailModal from './components/Table/components/FailModal'

// hooks
import useIsImpersonator from '@hooks/useIsImpersonator'

const updatePhotosBatch = (photosBatch, setPhotosBatchSize) => photo => {
  if (photosBatch.size <= 0) {
    photosBatch.set(photo.publicId, photo)
  } else if (!photosBatch.has(photo.publicId)) {
    photosBatch.set(photo.publicId, photo)
  } else {
    photosBatch.delete(photo.publicId)
  }
  setPhotosBatchSize(photosBatch.size)
}

const batchUpdateImages = (
  status,
  setBatchStatus,
  setShowConfirmation
) => () => {
  setBatchStatus(status)
  setShowConfirmation(true)
}

const moderatorCollection = new Collection(REST_API_ENDPOINTS.moderator)
const loadImages = (search, cb) => {
  moderatorCollection.readSigned(
    {
      params: {
        itemsPerPage: 500,
        moderationStatus: search.status || IMAGE_STATUSES.pending,
        moderationType: search.type || IMAGE_TYPES.photo
      }
    },
    (err, res = {}) => {
      const data = res.data || {}
      const results = data.results || []
      err ? cb(err) : cb(null, results)
    }
  )
}

const photosBatch = new Map()

const Moderator = props => {
  const { location, path } = props

  const [photos, setPhotos] = useState([])
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isPhotoUpdating, setIsPhotoUpdating] = useState(false)
  const [bigImage, setBigImage] = useState(null)

  const [photosBatchSize, setPhotosBatchSize] = useState(photosBatch.size)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [failedImages, setFailedImages] = useState(null)
  const [batchStatus, setBatchStatus] = useState(IMAGE_STATUSES.pending)

  const memoSearch = useMemo(() => Url.parseSearch(location.search), [
    location.search
  ])
  const currentType = parseInt(memoSearch.type, 10) || IMAGE_TYPES.photo

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    loadImages(memoSearch, (err, photos) => {
      setIsLoading(false)
      err ? setError(true) : setPhotos(photos)
    })
  }, [memoSearch])

  const refreshPhotos = useCallback(
    (err, res = {}) => {
      if (err) {
        ToastNotify.error(
          `There was an error while loading the ${
            currentType === IMAGE_TYPES.photo ? 'images' : 'logos'
          }, please try again.`
        )
        return
      }

      const succeededImages = res.successful || []
      const failedImages = res.failed || []
      const status = res.status || null

      if (succeededImages.length > 0) {
        const imagesIds = succeededImages.map(image => image.assetId)
        ToastNotify.success(
          `${succeededImages.length} ${
            currentType === IMAGE_TYPES.photo ? 'image' : 'logo'
          }${
            succeededImages.length > 1 ? 's were' : ' was'
          }  ${getStatusAsString(status)}.`
        )
        setPhotos(photos.filter(photo => !imagesIds.includes(photo.publicId)))
        loadImages(memoSearch, (err, photos) => {
          err ? setError(true) : setPhotos(photos)
          setIsPhotoUpdating(false)
        })
      }

      if (failedImages.length > 0) {
        setFailedImages({ photos: failedImages, status })
        setIsPhotoUpdating(false)
      }
    },
    [memoSearch, photos, currentType]
  )

  const currentStatus =
    parseInt(memoSearch.status, 10) || IMAGE_STATUSES.pending
  const multipleSelection = currentStatus === IMAGE_STATUSES.pending

  return (
    <>
      <Header
        showBatchActions={photosBatchSize > 0}
        onApproveBatch={batchUpdateImages(
          IMAGE_STATUSES.approved,
          setBatchStatus,
          setShowConfirmation
        )}
        onRejectBatch={batchUpdateImages(
          IMAGE_STATUSES.rejected,
          setBatchStatus,
          setShowConfirmation
        )}
        onSearchChange={value => {
          photosBatch.clear()
          setPhotosBatchSize(photosBatch.size)
          const combinedParsedUrlSearch = {
            ...memoSearch,
            ...value
          }
          Url.updateSearch(path, combinedParsedUrlSearch)
        }}
        parsedLocationSearch={memoSearch}
      />
      {!error ? (
        isLoading ? (
          `${
            currentType === IMAGE_TYPES.photo ? 'Images' : 'Logos'
          } are being loaded...`
        ) : (
          <Table
            photos={photos}
            multipleSelection={multipleSelection}
            onPhotoUpdated={refreshPhotos}
            setIsPhotoUpdating={setIsPhotoUpdating}
            isPhotoUpdating={isPhotoUpdating || photosBatchSize > 0}
            setBigImage={setBigImage}
            photosBatch={photosBatch}
            updatePhotosBatch={updatePhotosBatch(
              photosBatch,
              setPhotosBatchSize
            )}
          />
        )
      ) : (
        `There was an error while loading the ${
          currentType === IMAGE_TYPES.photo ? 'images' : 'logos'
        }, please try again.`
      )}
      {bigImage && (
        <ImageEditor
          imageToEdit={bigImage}
          onCancel={() => setBigImage(null)}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          status={batchStatus}
          currentType={currentType}
          photos={Array.from(photosBatch.values())}
          closeModal={() => {
            setShowConfirmation(null)
          }}
          onConfirm={({ photos, closeModal }) => {
            batchUpdatePhotos(photos, batchStatus, (err, updatedImages) => {
              refreshPhotos(err, updatedImages)
              photosBatch.clear()
              setPhotosBatchSize(photosBatch.size)
              closeModal()
            })
          }}
        />
      )}
      {failedImages && (
        <FailModal
          currentType={currentType}
          photos={failedImages.photos}
          status={failedImages.status}
          closeModal={() => {
            setFailedImages(null)
          }}
        />
      )}
    </>
  )
}

Moderator.propTypes = {
  path: string,
  location: object
}

Moderator.defaultProps = {
  path: '/',
  location: { search: '' }
}

const ModeratorWrapper = props => {
  const [validating, setValidating] = useState(true)
  const [userType, setUserType] = useState(null)
  const isImpersonator = useIsImpersonator()
  const breadcrumbs = [
    { key: 'moderator', text: 'Community Content Status', url: props.path }
  ]

  useEffect(() => {
    setValidating(true)
    Auth.isoIsImpersonating((_, isImpersonating) => {
      if (isImpersonating) {
        setUserType(IMPERSONATED_USER_ROLES)
      } else {
        setUserType(USER_ROLES)
      }
      setValidating(false)
    })
  }, [isImpersonator])

  return (
    <Layout
      className="baco-white"
      breadcrumbs={breadcrumbs}
      flagName="moderator"
    >
      {validating ? (
        <p>Validating user...</p>
      ) : (
        <PrivateRoute
          userType={userType}
          roles={[MODERATOR_ROLE]}
          component={Moderator}
          {...props}
        />
      )}
    </Layout>
  )
}

ModeratorWrapper.propTypes = {
  path: string
}

ModeratorWrapper.defaultProps = {
  path: '/'
}

export default ModeratorWrapper
