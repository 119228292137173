import React from 'react'
import { string, any, bool } from 'prop-types'
import cx from 'classnames'

// components
import Card from '@components/Card'
import Button from '@components/Button'

// styles
import styles from './_.module.scss'

// helpers
const FlatButton = props => (
  <Button
    {...props}
    palette="flat"
    className={cx(props.className, 'pa-2')}
    type="button"
    style={{ backgroundColor: 'transparent' }}
  />
)

FlatButton.propTypes = {
  className: string
}
FlatButton.defaultProps = {
  className: ''
}

const Overlay = props => (
  <div
    {...props}
    className={cx(
      'po-a',
      'pa-2',
      styles.overlay
    )}
  />
)

const ImagePreviewer = props => {
  return (
      <Card
        className={cx(styles.imagePreviewer, props.className, { [styles.imagePreviewerSmall]: props.isSmall })}
        style={{
          backgroundImage: `url(${props.imageUrl})`
        }}
      >
        {
          props.overlay && <Overlay children={props.overlay} />
        }

        <section
          className={cx(
            styles.image,
            'bora-tl',
            'bora-tr',
            props.actions ? null : 'bora-bl bora-br'
          )}
        />
        {props.actions && (
          <footer className={cx('par-2', 'di-f', 'juco-sb', styles.footer)}>
            {props.actions}
          </footer>
        )}
      </Card>
  )
}

ImagePreviewer.propTypes = {
  imageUrl: string.isRequired,
  className: string,
  actions: any,
  overlay: any,
  isSmall: bool
}

ImagePreviewer.defaultProps = {
  className: '',
  actions: null,
  overlay: null,
  isSmall: false
}

ImagePreviewer.FlatButton = FlatButton

export default ImagePreviewer
