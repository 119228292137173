import React, { Component } from 'react'
import { node, string, func, bool } from 'prop-types'
import cx from 'classnames'

import styles from './_.module.scss'

export default class Select extends Component {
  render() {
    const {
      children,
      className,
      onChange,
      readOnly,
      errorMessage,
      ...rest
    } = this.props
    return (
      <div
        className={cx('di-f', 'fldi-c', {
          [styles.withError]: !!errorMessage
        })}
      >
        <select
          onChange={onChange}
          className={cx('pay-1', 'pal-2', 'par-4', styles.select, className)}
          readOnly={readOnly}
          {...rest}
        >
          {children}
        </select>
        <div
          className={cx(
            'fosi-1',
            'teal-l',
            'pax-3',
            'pay-1',
            styles.errorMessage
          )}
        >
          {errorMessage}
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  children: node,
  className: string,
  readOnly: bool,
  errorMessage: string,
  onChange: func
}

Select.defaultProps = {
  children: null,
  className: '',
  readOnly: false,
  errorMessage: null,
  onChange: () => null
}
