import React, { Component } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { any, func, string, bool } from 'prop-types'
import Media from 'react-media'
import cx from 'classnames'

// components
import Modal from '@components/Modal'
import ConditionalFormattedTextArea from '@components/ConditionalFormattedTextArea'
import TextField from '@components/TextField'
import Select from '@components/Select'
import Checkbox from '@components/Checkbox'
import FeatureToggle from '@components/FeatureToggle'

import Controls from './components/Controls'
import Actions from './components/Actions'

// modules
import Cloudinary from '@modules/cloudinary'

// styles
import styles from './_.module.scss'

export function imageFileToDataURI(imageFile, callback) {
  let fileReader = new FileReader()
  fileReader.onload = event => callback(event.target.result)
  fileReader.readAsDataURL(imageFile)
}

const handleSaveWithCanvas = (ctx, canvas, cb) => {
  const canvasImage = canvas.getImage()
  const scaledCanvasImage = canvas.getImageScaledToCanvas()

  canvasImage.toBlob(largeBlob => {
    cb(
      ctx.props.imageFile,
      largeBlob,
      scaledCanvasImage.toDataURL(),
      ctx.state.model
    )
  }, 'image/jpeg')
}

const TextFieldWithLabel = ({ label, children }) => (
  <label>
    <div className="mat-2 fosi-1 co-gray-3">{label}</div>
    {children}
  </label>
)

TextFieldWithLabel.propTypes = {
  label: string.isRequired,
  children: any
}

TextFieldWithLabel.defaultProps = {
  children: null
}

const photoTypes = [
  { value: 'common_area', label: 'Common Area' },
  { value: 'exterior', label: 'Exterior' },
  { value: 'interior', label: 'Interior' }
]

// accessors
const STATE_ROTATE = 'rotate'
const STATE_WIDTH = 'width'
const STATE_HEIGHT = 'height'
const STATE_SCALE = 'scale'
const STATE_IMAGE_PREVIEW = 'imageFile'
const STATE_MODEL = 'model'
const STATE_DEVICE_IS_LARGE = 'deviceIsLarge'

const calcHeight = width => width - width / 3
const SMALL_WIDTH = 314
const SMALL_HEIGHT = calcHeight(SMALL_WIDTH)
const LARGE_WIDTH = 600
const LARGE_HEIGHT = calcHeight(LARGE_WIDTH)

const updateRotationBasedOnDeviceSize = (ctx, deviceIsLarge, rotateIsSame) => {
  const width = deviceIsLarge ? LARGE_WIDTH : SMALL_WIDTH
  const height = deviceIsLarge ? LARGE_HEIGHT : SMALL_HEIGHT

  ctx.setState(state => {
    const updatedRotate = rotateIsSame
      ? state[STATE_ROTATE]
      : state[STATE_ROTATE] + 90
    const updatedState = {
      [STATE_ROTATE]: updatedRotate
    }

    if ((updatedRotate / 2) % 2) {
      updatedState[STATE_WIDTH] = height
      updatedState[STATE_HEIGHT] = width
    } else {
      updatedState[STATE_WIDTH] = width
      updatedState[STATE_HEIGHT] = height
    }

    return updatedState
  })

  return null
}

class ImageEditor extends Component {
  constructor(props) {
    super(props)
    this.canvas = React.createRef()
    this.state = {
      [STATE_ROTATE]: 0,
      [STATE_WIDTH]: SMALL_WIDTH,
      [STATE_HEIGHT]: SMALL_HEIGHT,
      [STATE_SCALE]: 1,
      [STATE_IMAGE_PREVIEW]: null,
      [STATE_MODEL]: {
        type: photoTypes[0].value,
        ...(props.imageToEdit ? props.imageToEdit.metadata : {})
      },
      [STATE_DEVICE_IS_LARGE]: false
    }
  }

  componentDidMount() {
    this.props.isCanvas &&
      imageFileToDataURI(this.props.imageFile, preview =>
        this.setState({ [STATE_IMAGE_PREVIEW]: preview })
      )
  }

  componentDidUpdate(prevProps, prevState) {
    const prevDeviceIsLarge = prevState[STATE_DEVICE_IS_LARGE]
    const nextDeviceIsLarge = this.state[STATE_DEVICE_IS_LARGE]
    const prevRotate = prevState[STATE_ROTATE]
    const nextRotate = this.state[STATE_ROTATE]

    if (prevDeviceIsLarge != nextDeviceIsLarge) {
      updateRotationBasedOnDeviceSize(
        this,
        nextDeviceIsLarge,
        prevRotate === nextRotate
      )
    }

    if(this.props.isCanvas){
      const prevImageFile = prevProps.imageFile
      const nextImageFile = this.props.imageFile

      if (
        prevImageFile.name + prevImageFile.size !==
        nextImageFile.name + nextImageFile.size
      ) {
        imageFileToDataURI(nextImageFile, preview =>
          this.setState({
            [STATE_IMAGE_PREVIEW]: preview,
            [STATE_MODEL]: {},
            [STATE_ROTATE]: 0,
            [STATE_SCALE]: 1
          })
        )
      }
    }
  }

  render() {
    const {
      [STATE_ROTATE]: rotate,
      [STATE_WIDTH]: width,
      [STATE_HEIGHT]: height,
      [STATE_SCALE]: scale,
      [STATE_IMAGE_PREVIEW]: preview,
      [STATE_MODEL]: model,
      [STATE_DEVICE_IS_LARGE]: deviceIsLarge
    } = this.state

    const { onSave, onCancel, isCanvas, hasFeatureCheckbox, imageToEdit, imageFile, readOnly } = this.props

    return (
      <Modal
        header={
          <div className={cx('di-f', styles.header)}>
            {
              !readOnly && isCanvas && (
                <section className="di-f alit-c mab-2">
                  <Controls
                    onRotate={() => {
                      updateRotationBasedOnDeviceSize(this, deviceIsLarge, false)
                    }}
                    onScale={event =>
                      this.setState({ [STATE_SCALE]: event.target.value })
                    }
                    scale={+scale}
                  />
                </section>
              )
            }

            <span className="co-gray-3 pab-2">
              Images are all cropped to 3:2 aspect ratio.
            </span>
          </div>
        }
        footer={() => (
          <Actions
            onCancel={() => onCancel(imageFile)}
            onSave={() => isCanvas ? handleSaveWithCanvas(this, this.canvas.current, onSave) : onSave(this.state[STATE_MODEL])}
            className={styles.actions}
            save={isCanvas && !readOnly}
          />
        )}
        onToggleActive={() => onCancel(imageFile)}
      >

        <Media
          query="(min-width: 767px)"
          onChange={matches => {
            this.setState({ [STATE_DEVICE_IS_LARGE]: matches })
          }}
        />

        {
          isCanvas ? (
            <AvatarEditor
              ref={this.canvas}
              image={preview}
              width={+width}
              height={+height}
              border={20}
              color={[0, 159, 150, 0.5]}
              scale={+scale}
              rotate={+rotate}
            />
          ) : (
            <div
              className={cx("wi-100", styles.previewImage)}
              style={{
                width: +width,
                height: +height,
                background: `#EDEFF2 url("${Cloudinary.makeThumbnailFromSecureUrl(imageToEdit.secureUrl, 700)}") no-repeat center`,
                backgroundSize: 'auto 100%'
              }}
            />
          )
        }

        <FeatureToggle
          name="imageMetadata"
        >
          <TextFieldWithLabel label="* Photo type">
            <Select
              name="type"
              value={model.type}
              onChange={event =>
                this.setState({
                  [STATE_MODEL]: { ...model, type: event.target.value }
                })
              }
              readOnly={readOnly}
            >
              {photoTypes.map(option => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </TextFieldWithLabel>

          <TextFieldWithLabel label="Title">
            <TextField
              name="caption"
              palette="white"
              placeholder="Enter a title"
              onChange={event => {
                const value = event.target.value
                this.setState({
                  [STATE_MODEL]: { ...model, caption: value }
                })
              }}
              readOnly={readOnly}
            />
          </TextFieldWithLabel>

          <TextFieldWithLabel label="Description">
            <ConditionalFormattedTextArea
              name="alt"
              min={0}
              max={60}
              placeholder="Write a description"
              maxError="Please limit your description to 60 characters or fewer."
              recommended={{ min: 0, max: 60 }}
              value={model.alt}
              onChange={value =>
                this.setState({
                  [STATE_MODEL]: { ...model, alt: value }
                })
              }
            />
          </TextFieldWithLabel>
        </FeatureToggle>

        {
          !readOnly && isCanvas && hasFeatureCheckbox && (
            <Checkbox
              onClick={value =>
                this.setState({
                  [STATE_MODEL]: { ...model, isFeatured: value }
                })
              }
              name="isFeatured"
              title="Make this the featured photo"
              className={cx('pat-2', styles.checkbox)}
            />
          )
        }
      </Modal>
    )
  }
}

ImageEditor.propTypes = {
  imageFile: any,
  imageToEdit: any,
  isCanvas: bool,
  readOnly: bool,
  hasFeatureCheckbox: bool,
  onCancel: func,
  onSave: func
}

ImageEditor.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  isCanvas: false,
  readOnly: false,
  hasFeatureCheckbox: true,
  imageToEdit: null,
  imageFile: null
}

export default ImageEditor
