import React, { Component } from 'react'
import { func, node, string } from 'prop-types'

class Row extends Component {
  render() {
    const { onClick, className } = this.props
    return (
      <tr onClick={onClick} className={className}>
        {this.props.children}
      </tr>
    )
  }
}

Row.propTypes = {
  children: node,
  className: string,
  onClick: func
}

Row.defaultProps = {
  className: null,
  onClick: () => null,
  children: null
}

export default Row
