import React from 'react'
import cx from 'classnames'
import { string, func, oneOfType, number, bool } from 'prop-types'

// hoc
import withPalette from '@hoc/withPalette'

//  modules
import DOM from '@modules/dom'

// components
import Icon from '@components/Icon'
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

const Checkbox = props => {
  const { id, name, palette, className, errorMessage, ...inputProps } = props
  const uniqueId = id || DOM.getUniqueId(name)
  const paletteSelected = `checkbox${palette
    .charAt(0)
    .toUpperCase()}${palette.slice(1)}`

  return (
    <div
      className={cx('di-f', 'fldi-c', {
        [styles.withError]: !!errorMessage
      })}
    >
      <div className={cx('di-f', 'alit-c', className)}>
        <div className={cx('di-f', 'po-r', 'mar-2')}>
          <input
            {...inputProps}
            id={uniqueId}
            className={cx(
              'di-f',
              'cu-p',
              styles.checkbox,
              styles[paletteSelected] || '',
              { [styles.checkboxDisabled]: inputProps.disabled }
            )}
            type="checkbox"
          />
          <Icon className="po-a" name="check" size={12} />
        </div>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  id: string,
  name: string,
  className: string,
  value: oneOfType([string, number]),
  title: string,
  palette: string,
  defaultValue: oneOfType([string, number, bool]),
  checked: bool,
  errorMessage: string,
  onChange: func
}

Checkbox.defaultProps = {
  id: null,
  name: null,
  className: null,
  value: undefined,
  title: null,
  palette: THEMES.primary,
  defaultValue: undefined,
  checked: false,
  errorMessage: null,
  onChange: () => null
}

export default withPalette(Checkbox)
