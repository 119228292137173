import React from 'react'
import cx from 'classnames'
import { string, oneOf } from 'prop-types'

// styles
import styles from './_.module.scss'

const LoadingMessage = ({ message, spacing }) => {
  return (
    <section
      className={cx(
        'di-f',
        'fosi-2',
        'co-primary-3',
        'juco-c',
        'alit-c',
        'fowe-b',
        `pa-${spacing}`,
        'teal-c',
        styles.loadingMessage
      )}
    >
      {message}
    </section>
  )
}

LoadingMessage.propTypes = {
  message: string.isRequired,
  spacing: oneOf([1, 2, 3, 4])
}

LoadingMessage.defaultProps = {
  spacing: 4
}

export default LoadingMessage
