// Modules
import { Collection } from '@modules/rest-api'
import {
  REST_API_ENDPOINTS,
  API_VERSION,
  IMAGE_STATUSES
} from '@modules/constants'

export const getStatusAsString = (status, pastTense = true) => {
  return status === IMAGE_STATUSES.approved
    ? `approve${pastTense ? 'd' : ''}`
    : status === IMAGE_STATUSES.rejected
    ? `reject${pastTense ? 'ed' : ''}`
    : `update${pastTense ? 'd' : ''}`
}

export const batchUpdatePhotos = (photos, newStatus, cb) => {
  const data = {
    data: photos.map(photo => ({ assetId: `${photo.publicId}`, newStatus }))
  }

  const cloudinaryCollection = new Collection(
    REST_API_ENDPOINTS.assetChangeStatus,
    API_VERSION
  )

  cloudinaryCollection.patchSigned(data, (err, res) => {
    if (err) {
      console.warn(err)
      cb(err, { status: newStatus })
    } else {
      const data = res.data || {}
      const results = data.results || {}
      cb(null, { ...results, status: newStatus })
    }
  })
}
