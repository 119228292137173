import React, { Component } from 'react'
import { withFormsy } from 'formsy-react'
import { any, string, func, number, bool } from 'prop-types'
import TextAreaAutosize from 'react-textarea-autosize'
import cx from 'classnames'

// styles
import styles from './_.module.scss'

export class TextArea extends Component {
  constructor(props) {
    super(props)
    this.textArea = React.createRef()
  }
  render() {
    const {
      getValue,
      setValue,
      placeholder,
      disabled,
      readOnly,
      name,
      className,
      containerClass,
      initialValue,
      onChange,
      maxLength,
      children,
      defaultValue,
      withBorder,
      errorMessage
    } = this.props
    return (
      <div
        className={cx(
          'di-f',
          'fldi-c',
          'pat-2',
          'pab-4',
          'pax-3',
          'po-r',
          {
            'bowi-1': withBorder,
            'bost-s': withBorder,
            'boco-gray-3': withBorder,
            [styles.withError]: !!errorMessage,
            [styles.disabled]: disabled
          },
          styles.container,
          containerClass
        )}
        onClick={() => {
          const { current } = this.textArea || {}
          current && current.focus()
        }}
      >
        <TextAreaAutosize
          inputRef={this.textArea}
          useCacheForDOMMeasurements={true}
          className={cx('fosi-2', 'mab-4', styles.textArea, className)}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          onChange={event => {
            onChange(event)
            setValue(event.currentTarget.value)
          }}
          defaultValue={defaultValue}
          value={getValue() || initialValue}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        <div
          className={cx(
            'fosi-1',
            'teal-l',
            'pax-3',
            'pay-1',
            styles.errorMessage
          )}
        >
          {errorMessage}
        </div>

        {children}
      </div>
    )
  }
}

TextArea.propTypes = {
  children: any,
  className: string,
  containerClass: string,
  errorMessage: string,
  getValue: func,
  initialValue: string,
  maxLength: number,
  name: string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
  defaultValue: string,
  withBorder: bool,
  setValue: func,
  onChange: func
}

TextArea.defaultProps = {
  name: '__nameless__',
  onChange: () => null,
  className: '',
  containerClass: '',
  errorMessage: '',
  getValue: () => {},
  initialValue: undefined,
  placeholder: '',
  disabled: false,
  readOnly: false,
  maxLength: null,
  defaultValue: undefined,
  withBorder: true,
  setValue: () => {},
  children: null
}

export default withFormsy(TextArea)
