import React from 'react'
import { string, func } from 'prop-types'
import cx from 'classnames'

import styles from './_.module.scss'

const Range = ({ className, onChange, ...rest }) => (
  <input
    {...rest}
    className={cx(styles.range, className)}
    onChange={event => onChange(event)}
    type="range"
  />
)

Range.propTypes = {
  className: string,
  onChange: func
}
Range.defaultProps = {
  className: '',
  onChange: () => null
}

export default Range
