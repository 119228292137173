import React from 'react'
import cx from 'classnames'
import { compose } from 'redux'
import { withFormsy } from 'formsy-react'
import { bool, string, func, oneOfType, number, oneOf } from 'prop-types'

// hoc
import withPalette from '@hoc/withPalette'

// modules
import DOM from '@modules/dom'
import {
  EMPTY,
  FILLED,
  PARTIALLY_FILLED,
  TITLE_BEFORE_INPUT,
  TITLE_AFTER_INPUT
} from '../../modules/constants'
// components
import Icon from '@components/Icon'
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

// helpers
const handleChange = (value, state, cb) => {
  cb({ value, state })
}
const handleOnClick = (isChecked, cb = () => null) => {
  const newStatus =
    isChecked === EMPTY
      ? FILLED
      : isChecked === PARTIALLY_FILLED
      ? FILLED
      : EMPTY
  cb(newStatus)
}

export const CheckboxComplexFormsy = ({
  id,
  name,
  title,
  className,
  palette,
  formValue,
  getValue,
  setValue,
  onChange,
  hasTextColor,
  onClick,
  titlePosition,
  titleClassName,
  disabled
}) => {
  const isChecked = getValue() || EMPTY
  const uniqueId = id || DOM.getUniqueId()
  const colorFullClasses = cx(styles.checkboxLabelColorfull)
  const paletteSelected = `checkbox${palette
    .charAt(0)
    .toUpperCase()}${palette.slice(1)}`
  return (
    <div
      className={cx(
        'di-f',
        'cu-p',
        'juco-sb',
        styles.checkbox,
        styles[paletteSelected] || '',
        className
      )}
      onKeyDown={event =>
        DOM.accessibilityClick(
          event,
          hit =>
            hit &&
            !disabled &&
            handleOnClick(isChecked, newStatus => {
              setValue(newStatus)
              onClick(newStatus)
            })
        )
      }
      onClick={event => {
        event.preventDefault()
        !disabled &&
          handleOnClick(isChecked, newStatus => {
            setValue(newStatus)
            onClick(newStatus)
          })
      }}
      role="checkbox"
      aria-checked={
        isChecked === FILLED
          ? 'true'
          : isChecked === PARTIALLY_FILLED
          ? 'mixed'
          : 'false'
      }
      aria-labelledby={uniqueId}
      tabIndex={0}
      aria-disabled={disabled}
    >
      {titlePosition === TITLE_BEFORE_INPUT && title && (
        <label
          className={cx(
            'cu-p',
            styles.checkboxLabel,
            {
              [colorFullClasses]: hasTextColor && isChecked === FILLED
            },
            titleClassName
          )}
          htmlFor={uniqueId}
        >
          {title}
        </label>
      )}
      <div className={cx('di-f', 'alit-c', 'po-r', styles.checkboxContainer)}>
        <input
          className={cx('po-a', styles.checkboxInput)}
          onChange={event => {
            handleChange(event.currentTarget.value, isChecked, onChange)
          }}
          id={uniqueId}
          name={name}
          type="checkbox"
          value={formValue}
          checked={isChecked}
          aria-readonly
          tabIndex={-1}
          disabled={disabled}
        />
        {isChecked === FILLED && (
          <Icon
            className={cx(
              'po-a',
              styles.checkboxIcon,
              styles.checkboxIconCheck
            )}
            name="check"
            size={12}
          />
        )}
        {isChecked === PARTIALLY_FILLED && (
          <Icon
            className={cx(
              'po-a',
              styles.checkboxIcon,
              styles.checkboxIconCheck
            )}
            name="dash"
            size={22}
          />
        )}
      </div>
      {titlePosition === TITLE_AFTER_INPUT && title && (
        <label
          className={cx('cu-p', 'pal-2', styles.checkboxLabel, {
            [colorFullClasses]: hasTextColor && isChecked === FILLED
          })}
          htmlFor={uniqueId}
        >
          {title}
        </label>
      )}
    </div>
  )
}
CheckboxComplexFormsy.propTypes = {
  name: string.isRequired,
  id: string,
  className: string,
  title: string,
  palette: string,
  hasTextColor: bool,
  disabled: bool,
  formValue: oneOfType([number, string]),
  titlePosition: oneOf([TITLE_BEFORE_INPUT, TITLE_AFTER_INPUT]),
  titleClassName: string,
  getValue: func,
  setValue: func,
  onChange: func,
  onClick: func
}
CheckboxComplexFormsy.defaultProps = {
  id: null,
  name: '__nameless__',
  className: '',
  title: '',
  palette: THEMES.primary,
  value: EMPTY,
  hasTextColor: false,
  disabled: false,
  formValue: '',
  titlePosition: TITLE_BEFORE_INPUT,
  titleClassName: null,
  getValue: () => null,
  setValue: () => null,
  onChange: () => null,
  onClick: () => null
}

export default compose(
  withPalette,
  withFormsy
)(CheckboxComplexFormsy)
