import React from 'react'
import { object, func, bool } from 'prop-types'

// components
import Select from '@components/Select'
import Actions from '../Table/components/Actions'
// import Paginator from '@components/Paginator' // TODO: Pagination

// modules
import { optionGroups } from './modules/constants'

const OptionsGroup = ({ optionGroup, selectAttrs }) => (
  <label className="fosi-1">
    <p>{optionGroup.label}</p>
    <Select {...selectAttrs}>
      {optionGroup.options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </label>
)

OptionsGroup.propTypes = {
  optionGroup: object.isRequired,
  selectAttrs: object
}

OptionsGroup.defaultProps = {
  selectAttrs: {}
}

const Header = ({
  showBatchActions,
  onSearchChange,
  parsedLocationSearch,
  onApproveBatch,
  onRejectBatch
}) => (
  <header className="pab-2">
    <nav className="di-f pay-3 juco-sb">
      <div className="di-f">
        {optionGroups.map(optionGroup => (
          <section key={optionGroup.key} className="mar-4">
            <OptionsGroup
              optionGroup={optionGroup}
              selectAttrs={{
                onChange: a =>
                  onSearchChange({
                    [optionGroup.key]: a.target.value
                  }),
                value: parsedLocationSearch[optionGroup.key]
              }}
            />
          </section>
        ))}
      </div>
      <div className="di-f pat-4">
        {
          showBatchActions && (
            <Actions
              horizontal={true} 
              onApprove={onApproveBatch}
              onReject={onRejectBatch}
            />
          )
        }
      </div>

      {/* TODO: Pagination */}
      {/* <Paginator
        onClickStep={page => {
          onSearchChange({ page })
        }}
        currentPage={+parsedLocationSearch.page || 1}
      /> */}
    </nav>
  </header>
)

Header.propTypes = {
  showBatchActions: bool,
  parsedLocationSearch: object,
  onSearchChange: func,
  onApproveBatch: func,
  onRejectBatch: func
}
Header.defaultProps = {
  showBatchActions: false,
  parsedLocationSearch: {},
  onSearchChange: () => null,
  onApproveBatch: () => null,
  onRejectBatch: () => null
}

export default Header
