import React from 'react'
import Simple from '../Simple'
import { withFormsy } from 'formsy-react'
import { func, oneOfType, bool, string, number } from 'prop-types'

const CheckboxWithFormsy = ({
  onChange,
  setValue,
  getValue,
  defaultValue,
  ...rest
}) => {
  const inputProps = Object.assign({}, rest)
  if (defaultValue) inputProps.defaultValue = defaultValue
  else if (getValue()) inputProps.value = getValue()
  return (
    <Simple
      {...inputProps}
      onChange={(name, value) => {
        onChange(name, value)
        setValue(value)
      }}
    />
  )
}

CheckboxWithFormsy.propTypes = {
  defaultValue: oneOfType([string, number, bool]),
  getValue: func,
  setValue: func,
  onChange: func
}

CheckboxWithFormsy.defaultProps = {
  defaultValue: null,
  getValue: () => null,
  setValue: () => null,
  onChange: () => null
}

export default withFormsy(CheckboxWithFormsy)
