import React, { Component } from 'react'
import cx from 'classnames'
import { string, bool, number, shape, func } from 'prop-types'

import TextArea from '@components/TextArea'
import LimitsDisclaimer from './components/LimitsDisclaimer'

// modules
import Formatter from '@modules/formatter'

// styles
import styles from './_.module.scss'

const MAX_LENGHT = 4000
const RECOMMENDED = { min: 300, max: 400 }
const STATE_MESSAGE = 'message'

class ConditionalFormattedTextArea extends Component {
  constructor(props) {
    super(props)
    let message = props.value || props.defaultValue
    if (props.supportHTML) {
      message = Formatter.stripHtmlTags(message)
    }
    this.state = {
      [STATE_MESSAGE]: message || ''
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const { value } = this.props
      this.setState({
        [STATE_MESSAGE]: value
      })
    }
  }

  render() {
    const {
      placeholder,
      showLimitsDisclaimer,
      min,
      minError,
      max,
      maxError,
      recommended,
      className,
      onChange,
      name,
      supportHTML,
      disabled,
      readOnly
    } = this.props
    const { [STATE_MESSAGE]: message } = this.state
    const length = message.length
    const maxLength = length === max ? message.length + 1 : -1
    return (
      <>
        <TextArea
          className={cx('fosi-2', 'pab-4', styles.textareaAutosize, className)}
          containerClass={styles.textareaContainer}
          onChange={event => {
            if (disabled || readOnly) return false
            this.setState({ [STATE_MESSAGE]: event.currentTarget.value })
            const textLength = event.currentTarget.value.length
            let message = event.currentTarget.value
            if (supportHTML) {
              message = Formatter.stripHtmlTags(message)
            }
            onChange(message, max > 0 && textLength <= max)
          }}
          name={name}
          placeholder={placeholder}
          initiaValue=""
          defaultValue={message}
          validations="isExisty"
          validationError="There is not a message"
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          required
        ></TextArea>
        {showLimitsDisclaimer && (
          <LimitsDisclaimer
            min={min}
            minError={minError}
            max={max}
            maxError={maxError}
            recommended={recommended}
            length={length}
          />
        )}
      </>
    )
  }
}

ConditionalFormattedTextArea.propTypes = {
  value: string,
  defaultValue: string,
  className: string,
  name: string,
  placeholder: string,
  showLimitsDisclaimer: bool,
  supportHTML: bool,
  disabled: bool,
  readOnly: bool,
  recommended: shape({
    min: number,
    max: number
  }),
  min: number,
  minError: string,
  max: number,
  maxError: string,
  onChange: func
}

ConditionalFormattedTextArea.defaultProps = {
  value: '',
  defaultValue: '',
  className: '',
  name: '__nameless__',
  placeholder: 'Write a description...',
  showLimitsDisclaimer: true,
  supportHTML: false,
  disabled: false,
  readOnly: false,
  recommended: RECOMMENDED,
  min: -1,
  minError: null,
  max: MAX_LENGHT,
  maxError: null,
  onChange: () => null
}

export default ConditionalFormattedTextArea
