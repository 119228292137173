import CheckboxComplexFormsy from './components/ComplexFormsy'
import CheckboxSimple from './components/Simple'
import CheckboxControlled from './components/Controlled'
import CheckboxWithFormsy from './components/WithFormsy'
import {
  EMPTY,
  FILLED,
  PARTIALLY_FILLED,
  TITLE_BEFORE_INPUT,
  TITLE_AFTER_INPUT
} from './modules/constants'

const Checkbox = CheckboxComplexFormsy

Checkbox.Simple = CheckboxSimple
Checkbox.Controlled = CheckboxControlled
Checkbox.SimpleFormsy = CheckboxWithFormsy

export {
  EMPTY,
  FILLED,
  PARTIALLY_FILLED,
  TITLE_BEFORE_INPUT,
  TITLE_AFTER_INPUT,
  CheckboxSimple,
  CheckboxWithFormsy
}

export default Checkbox
