import React, { Component } from 'react'
import cx from 'classnames'
import { node } from 'prop-types'

import styles from './_.module.scss'

class TableResponsive extends Component {
  render() {
    const { children } = this.props
    return <div className={cx(styles.tableResponsive)}>{children}</div>
  }
}

TableResponsive.propTypes = {
  children: node
}

TableResponsive.defaultProps = {
  children: null
}

export default TableResponsive
