import React, { memo } from 'react'
import { any, func, bool, string, oneOf } from 'prop-types'
import cx from 'classnames'

// components
import Card from '@components/Card'
import EventOutsideDetector from '@components/EventOutsideDetector'

import Header from './components/Header'

// styles
import styles from './_.module.scss'

export const SMALL_MODAL = 'small'
export const BIG_MODAL = 'big'

const SIZES = {
  [SMALL_MODAL]: 'Small',
  [BIG_MODAL]: 'Big'
}

const Modal = memo(props => {
  const exit = () => props.onToggleActive(false)
  const selectedSize = SIZES[props.size] || SIZES[BIG_MODAL]

  return props.isActive ? (
    <div className={styles.overlay}>
      <EventOutsideDetector.MouseDown
        onEvent={props.onBackdropClick ? props.onBackdropClick : exit}
      >
        <Card
          className={cx(styles.content, 'pa-3', 'fosi-2', props.className, styles[`content${selectedSize}`])}
          elevation={4}
        >
          <Header children={props.header} className={props.headerClassName} exit={exit} hideCloseButton={props.hideCloseButton} />
          {props.children}
          <section className={cx('mat-4', 'di-f', 'juco-fe', styles.footer)}>
            {props.footer(exit)}
          </section>
        </Card>
      </EventOutsideDetector.MouseDown>
    </div>
  ) : null
})

Modal.propTypes = {
  className: string,
  headerClassName: string,
  header: any,
  children: any,
  footer: func,
  isActive: bool,
  hideCloseButton: bool,
  size: oneOf([SMALL_MODAL, BIG_MODAL]),
  onToggleActive: func,
  onBackdropClick: func
}

Modal.defaultProps = {
  className: '',
  headerClassName: '',
  header: null,
  children: null,
  footer: () => null,
  isActive: true,
  hideCloseButton: false,
  size: SMALL_MODAL,
  onToggleActive: () => null,
  onBackdropClick: null
}

export default Modal
