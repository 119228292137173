import React from 'react'
import { any } from 'prop-types'

const Head = props => <thead>{props.children}</thead>

Head.propTypes = {
  children: any
}

Head.defaultProps = {
  children: null
}

export default Head
