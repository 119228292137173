import React from 'react'
import { func, string, bool } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'
import { THEMES } from '@components/constants'

const EditControls = ({
  disabled,
  disabledCancel,
  onSave,
  onCancel,
  className,
  okPalette,
  okText,
  hideOk,
  cancelText,
  hideCancel,
  cancelPalette
}) => {
  return (
  <section className={cx('di-f', 'juco-fe', 'fosi-2', 'wi-100', className)}>
    {!hideCancel && <Button
      className="pay-2 pax-5 mar-2 juco-c fowe-600"
      palette={cancelPalette || THEMES.white}
      onClick={onCancel}
      style={{ minWidth: '135px', minHeight: '40px' }}
      disabled={disabledCancel}
    >
      {cancelText}
    </Button>}
    {!hideOk && <Button
      className="pay-2 pax-5 juco-c fowe-600"
      palette={okPalette || THEMES.primary}
      onClick={onSave}
      type="submit"
      style={{ minWidth: '135px', minHeight: '40px' }}
      disabled={disabled}
    >
      {okText}
    </Button>}
  </section>
)}

EditControls.propTypes = {
  className: string,
  disabled: bool,
  disabledCancel: bool,
  cancelText: string,
  okText: string,
  okPalette: string,
  cancelPalette: string,
  hideOk: bool,
  hideCancel: bool,
  onCancel: func,
  onSave: func
}
EditControls.defaultProps = {
  disabled: false,
  disabledCancel: false,
  className: '',
  cancelText: 'Cancel',
  okText: 'Save',
  okPalette: null,
  cancelPalette: null,
  hideOk: false,
  hideCancel: false,
  onSave: () => null,
  onCancel: () => null
}

export default EditControls
