import React from 'react'
import cx from 'classnames'
import { any, func, string, bool } from 'prop-types'

import Icon from '@components/Icon'
import Button from '@components/Button'

import styles from './_.module.scss'
const Header = ({ children, className, hideCloseButton, exit }) => (
  <div className={cx('di-f', 'juco-sb', 'alit-s', className)}>
    <section className="flba-a" children={children} />
    {!hideCloseButton && (
      <Button
        palette="flat"
        className={cx('mal-4', 'op-2', styles.closeButton)}
        onClick={exit}
      >
        <Icon name="close" size={13} />
      </Button>
    )}
  </div>
)

Header.propTypes = {
  children: any,
  className: string,
  hideCloseButton: bool,
  exit: func
}

Header.defaultProps = {
  children: null,
  className: null,
  hideCloseButton: false,
  exit: () => null
}

export default Header
