import React from 'react'
import { func, string, number } from 'prop-types'
import cx from 'classnames'

// components
import Button from '@components/Button'
import Range from '@components/Range'
import Icon from '@components/Icon'

// styles
import styles from './_.module.scss'

const Controls = ({ onScale, onRotate, className, scale }) => {
  return (
    <section className={cx('di-f', 'alit-c', styles.controls, className)}>
      <Button onClick={onRotate} palette="flat">
        <div className="co-primary-3 di-f">
          <Icon name="circle-arrow" className="par-2" />
          Rotate
        </div>
      </Button>

      <div className="di-f mal-2">
        <Icon name="scale" className="par-2" />
        <Range
          min={1}
          max={5}
          onChange={event => {
            onScale(event)
          }}
          step={0.1}
          value={scale}
        />
      </div>
    </section>
  )
}

Controls.propTypes = {
  className: string,
  scale: number,
  onRotate: func,
  onScale: func
}

Controls.defaultProps = {
  onScale: () => null,
  onRotate: () => null,
  className: '',
  scale: 1
}

export default Controls
