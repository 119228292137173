import React, { useState } from 'react'
import { func, array, number } from 'prop-types'

import Modal, { SMALL_MODAL } from '@components/Modal'
import { IMAGE_TYPES } from '@modules/constants'
import LoadingMessage from '@components/EditEmployeeForm/components/LoadingMessage'
import Footer from './components/Footer'
import { getStatusAsString } from '../../../../modules/utils'

const ConfirmationModal = ({
  photos,
  status,
  closeModal,
  currentType,
  onConfirm
}) => {
  const [updatingUser, setUpdatingUser] = useState(false)

  return (
    <Modal
      className="po-r"
      size={SMALL_MODAL}
      footer={exit => {
        return (
          <Footer
            updating={updatingUser}
            exit={exit}
            setUpdating={setUpdatingUser}
            onConfirm={onConfirm}
            photos={photos}
          />
        )
      }}
      onToggleActive={() => closeModal()}
    >
      {updatingUser ? (
        <LoadingMessage
          message={`Updating selected ${
            currentType === IMAGE_TYPES.photo ? 'images' : 'logos'
          }`}
        />
      ) : (
        <div className="di-f fldi-c max-2">
          <h4 className="fowe-b mab-1">{`Are you sure you want to ${getStatusAsString(
            status,
            false
          )} ${photos.length > 1 ? 'these' : 'this'} ${
            photos.length !== 1 ? photos.length : ''
          } ${currentType === IMAGE_TYPES.photo ? 'image' : 'logo'}${
            photos.length > 1 ? 's' : ''
          }?`}</h4>
        </div>
      )}
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  photos: array,
  status: number,
  currentType: number,
  closeModal: func,
  onConfirm: func
}
ConfirmationModal.defaultProps = {
  photos: [],
  status: 0,
  currentType: 1,
  closeModal: () => null,
  onConfirm: () => null
}

export default ConfirmationModal
